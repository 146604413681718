import Noty, { Type, Options } from 'noty';

export type { Options as NotificationOptions };
export type NotificationType = Type | 'danger';
export function notify(type: NotificationType, text: string, options: Options = {}): void {
    if (type === 'danger') {
        type = 'error';
    }

    new Noty(Object.assign({
        text,
        type: type,
        timeout: 10_000,
        theme: 'bootstrap-v4',
        layout: 'topRight'
    }, options)).show();
}

export default notify;
