
export class SelectizeHelpers {
    static addOption(selectElement: HTMLSelectElement, newOption: { text: string, value: string }): void {
        const selectize = selectElement.selectize;

        selectize.addOption(newOption);
    }

    static updateOption(selectElement: HTMLSelectElement, option: Record<string, any>): void {
        const selectize = selectElement.selectize as any;
        const optionId = option[selectize.settings.valueField];

        if (optionId in selectize.options) {
            selectize.updateOption(optionId, option);
        }
    }
}
