const unsavedChangesIcon = `&nbsp;<span class="fa fa-fw fa-save text-info" title="Er zijn onopgeslagen wijzigingen binnen dit tabblad."></span>`;
const validationErrorsIcon = `&nbsp;<span class="far fa-fw fa-exclamation-square text-danger" title="Er zijn invoerfouten binnen dit tabblad."></span>`;

class ObservableForm {
    form: HTMLFormElement;
    tab: HTMLElement;
    originalData: string;
    data: string;

    constructor(form: HTMLFormElement, tab: HTMLElement, data: string) {
        this.form = form;
        this.tab = tab;
        this.originalData = data;
        this.data = data;

        this.bindListeners();
        this.checkValidationErrors();
    }

    get dirty() {
        return this.data !== this.originalData;
    }

    get hasErrors() {
        const inputs = this.form.querySelectorAll('input.is-invalid, select.is-invalid');

        return inputs.length > 0;
    }

    protected bindListeners() {
        const changeListener = (event: Event) => {
            const $input = $(event.target!);
            const $formGroup = $input.parents('.form-group');
            this.data = $(this.form).serialize();

            if ($input.val() !== '') {
                $formGroup.removeClass('has-warning');
                $formGroup.find('.form-watcher-notice').remove();
            }

            if ($formGroup.find('.form-watcher-notice').length > 0) {
                return;
            }

            if ($input.val() === '' && $input.attr('required')) {
                $formGroup.addClass('has-warning');

                let $after = $input;
                const $inputGroup = $formGroup.find('.input-group');
                if ($inputGroup.length > 0) {
                    $after = $inputGroup;
                }

                $after.after(this.getHelpText('Voer alstublieft dit veld in.'));
            }

            this.toggleUnsavedChangesIcon();
        };

        this.form.addEventListener('input', changeListener);
        this.form.addEventListener('change', changeListener);

        $(this.form).find('select').on('change', changeListener);

        // Invalid events worden fired op de inputs zelf, niet het formulier.
        $(this.form).find('input, select').each(((index, element) => {
            element.addEventListener('invalid', (event: Event) => {
                const $input = $(event.target!);

                if ($(this.tab).has('.fa-exclamation-square').length === 0) {
                    $(this.tab).append(validationErrorsIcon);
                }

                if ($input.attr('required')) {
                    $input.tooltip({ title: 'Voer alstublieft dit veld in.' });
                }

                $input.parents('.form-group').addClass('has-warning');

                $(this.tab).tooltip({ title: 'Er zijn invoerfouten binnen dit tabblad.' }).tooltip('show');

                setTimeout(() => {
                    $(this.tab).tooltip('hide');
                }, 3000);
            });
        }));
    }

    protected getHelpText(message: string) {
        return $(`<span class="form-watcher-notice help-block">${message}</span>`);
    }

    protected toggleUnsavedChangesIcon() {
        /**
         * Unsaved changes
         */
        if (this.dirty && $(this.tab).has('.fa-save').length === 0) {
            $(this.tab).append(unsavedChangesIcon);
        } else if (!this.dirty) {
            $(this.tab).find('.fa-save').remove();
        }
    }

    protected checkValidationErrors() {
        if (this.hasErrors && $(this.tab).has('.fa-exclamation-square').length === 0) {
            $(this.tab).append(validationErrorsIcon);
        }
    }
}

type FormChildElement = HTMLInputElement | HTMLButtonElement | HTMLSelectElement;

class FormWatcher {
    private static _forms: Map<HTMLElement, ObservableForm> = new Map();

    static init() {
        const forms = document.querySelectorAll('form:not(.ignore-watcher)');

        forms.forEach((form: HTMLFormElement) => {
            const tabId = $(form).parents('.tab-content').first().attr('id');
            const tab = document.querySelector(`[href="#tab-${tabId}"]`) as HTMLElement;
            FormWatcher._forms.set(form, new ObservableForm(form, tab, $(form).serialize()));
        });

        const navigatedToUrl = '';
        window.addEventListener('beforeunload', (event: BeforeUnloadEvent) => {
            return; // dit gaat nog fout bij een aantal formulieren. bijv de choose company pagina

            if (!global.isProduction) {
                return;
            }

            if (!(event.target instanceof Document)) {
                return;
            }

            const target = event.target as Document;

            // Form submit's niet preventen
            if (target.activeElement instanceof HTMLElement && Object.keys(target.activeElement ?? {}).includes('form')) {
                const { form, type } = target.activeElement as HTMLFormElement;

                const isSubmitButton = type === 'submit';
                const isWatchedForm = form !== null && this._forms.has(form);

                if (isSubmitButton || isWatchedForm) {
                    return;
                }
            }

            if (this.dirty() && !confirm()) {
                event.preventDefault();
                event.returnValue = '';
            }
        });
    }

    static dirty() {
        let dirty = false;
        this._forms.forEach((form) => {
            if (form.dirty) {
                dirty = true;
            }
        });

        return dirty;
    }
}

global.FormWatcher = FormWatcher;
export default FormWatcher;
