import $ from 'jquery';
import moment from 'moment';

export function initializeDatePicker(element: HTMLInputElement) {
    const format = $(element).data('format') || 'DD-MM-YYYY';

    let endDate = element.getAttribute('data-enddate');
    if (!endDate) {
        endDate = '';
    }

    $(element).datepicker({
        format: {
            toDisplay: function (date, _, language) {
                return moment(date).format(format);
            },
            toValue: function (date: string, _, language) {
                let newDate = moment(date, format);

                if (!newDate.isValid()) {
                    newDate = moment(date, 'DDMMYY');
                }

                if (!newDate.isValid()) {
                    return new Date();
                }

                return new Date(newDate.year(), newDate.month(), newDate.date(), 6, 0, 0);
            }
        },
        endDate,
        todayHighlight: true,
        orientation: $(element).data('orientation') || 'auto',
        autoclose: true,
        calendarWeeks: true,
    }).on('changeDate', function(e) {
        console.error(e);
        element.dispatchEvent(new Event('change', { bubbles: true }));
    });
}

export default function initializeDatePickers() {
    $('.date-picker').each((index, elem: HTMLInputElement) => {
        initializeDatePicker(elem);
    });
}
