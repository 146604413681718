import $ from 'jquery';
import initializeDatePickers from './datepicker';
import setCookie from './cookie';
import FormWatcher from './form-watcher';
import { notify } from '@/util/notify';
import { Routing } from '@/routing';
import ClickEvent = JQuery.ClickEvent;
import { SelectizeHelpers } from '@/util/selectize';
import { debounce } from 'lodash';

global.noty = notify;
global.agWorkspace = {
    documentLoaded: [],
    grids: new Map()
};

export function applyInputGroupDisables() {
    $('[disabled], [readonly], .disabled').each(function () {
        const inputGroup = $(this).closest('.input-group');
        if (!inputGroup.hasClass('disabled')) {
            inputGroup.addClass('disabled');
        }
    });
}

export const formatNumber = (number: string | number | null, minimumFractionDigits = 2, maximumFractionDigits = 2): string => {
    if (number === null) {
        return '';
    }

    const formattedNumber = parseFloat(number.toString());

    const numberFormat = new Intl.NumberFormat(navigator.language, {
        useGrouping: false,
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits,
    });

    return numberFormat.format(formattedNumber);
};

global.formatNumber = formatNumber;

export const formatCurrency = (value: number, isoCode: string, minimumFractionDigits = 2, maximumFractionDigits = 4): string => {
    return Intl.NumberFormat(navigator.language, {
        style: 'currency',
        currency: isoCode,
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits
    }).format(value);
};

export const parseInputFloat = (inputText: string, defaultZero = true): number => {
    if (typeof inputText === 'undefined') {
        return NaN;
    }

    const parsed = parseFloat(inputText.toString().replace(',', '.'));

    if (isNaN(parsed) && defaultZero) {
        return 0;
    }

    return parsed;
};

const resizeGrids = () => {
    const grids = document.getElementsByClassName('ag-grid');
    const windowHeight = window.innerHeight;
    const gridMargin = 15;
    Array.from(grids).forEach((grid: HTMLElement) => {
        if (grid.classList.contains('no-resize')) {
            return;
        }

        const viewportOffset = grid.getBoundingClientRect();
        const gridHeight = windowHeight - viewportOffset.top - gridMargin;
        grid.style.height = `${gridHeight}px`;
    });
};

const updateHandler = (ev: StorageEvent) => {
    if (ev.key === null) return;
    const newValue = ev.newValue;

    if (newValue === null) return;
    if (newValue === '') return;

    const [_, entityClass, optionId] = /food:update-form-value\((.*),(.*)\)/g.exec(ev.key) ?? [];

    if (entityClass === undefined || optionId === undefined) return;

    const selectElements = Array.from($(`select[data-entity-class]`)).filter(el => $(el).data('entity-class') === entityClass) as HTMLSelectElement[];

    if (selectElements.length === 0) return;

    selectElements.forEach(selectElement => {
        SelectizeHelpers.updateOption(selectElement, { text: newValue, value: optionId });
    });

    localStorage.removeItem(ev.key); // Remove event from 'queue'
};
window.addEventListener('storage', updateHandler);

$(document).on('click', '[data-route]', (e: ClickEvent) => {
    const $btn = $(e.currentTarget);
    const $field = $(`[name="${$btn.data('field')}"]`);

    window.open(Routing.generate($btn.data('route'), {
        id: $field.val(),
    }), '_blank');
});

$(function () {
    $('select:not(.no-selectize):not(.ql-header):not([readonly])').selectize({
        plugins: ['remove_button']
    });

    $('[data-toggle="tooltip"]').each(function () {
        const options = {};

        if ($(this).data('size')) {
            options['template'] = '<div class="tooltip tooltip-lg" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>';
        }

        $(this).attr('aria-label', $(this).attr('title') ?? '');
        $(this).addClass('hint--top');
    });

    initializeDatePickers();

    // Resize AgGrids als tab wordt geswitched.
    $('a[data-toggle="tab"]').on('shown.bs.tab', function () {
        global.agWorkspace.grids.forEach(grid => {

            if (typeof grid.gridOptions === 'undefined') {
                return;
            }

            if (!grid.gridOptions.api) {
                return;
            }

            grid.gridOptions?.columnApi?.autoSizeAllColumns();
        });
    });

    // Handle checkbox buttons
    $(document).on('click', '.btn-checkbox', function () {
        const $checkbox = $(this).find('input');
        $checkbox.prop('checked', !$checkbox.prop('checked'));
    });

    // Disable input groups
    applyInputGroupDisables();

    // AGGRID MULTI-GRID //
    global.agWorkspace.documentLoaded.forEach(function (loadAction) {
        loadAction();
    });

    FormWatcher.init();

    $(document).on('click', '.sidebar-toggle', (e) => {
        setCookie('sidebar-collapse', $('body').hasClass('sidebar-collapse') ? '1' : '0');
    });

    $(document).on('click', '.choose-company-link', function (e) {
        e.preventDefault();

        $('#choose_company_company')
            .val($(this).data('company'))
            .get(0).selectize.setValue($(this).data('company'));
        $('#choose_company').trigger('submit');
    });

    resizeGrids();
    window.addEventListener('resize', debounce(() => {
        resizeGrids();
    }, 250));

    const metaKey = navigator.platform.toUpperCase().indexOf('MAC') >= 0 ? '⌘' : 'Ctrl';
    $('input.input-website').prop('title', `${metaKey}-click to navigate to website`);

    $(document).on('click', 'input.input-website', function (e) {
        if (!e.metaKey) {
            return;
        }

        window.open(
            $(this).val() as string,
            '_blank' // <- This is what makes it open in a new window.
        );
    });

    $('input.input-email').prop('title', `${metaKey}-click to send an email`);

    $(document).on('click', 'input.input-email', function (e) {
        if (!e.metaKey) {
            return;
        }

        const addr = $(this).val() as string;
        window.location.href = `mailto:${addr}`;
    });

    $(document).on('change', '.tab-select', function () {
        $(`a[href="#${$(this).val()}"]`).tab('show');
    });
});
